import localFont from 'next/font/local';
interface Font {
  variable: string;
}
interface TypographyMap {
  [themeName: string]: Font[];
}

type ThemeName =
  | 'Vanilla'
  | 'Ziploc'
  | 'Off'
  | 'Autan'
  | 'Corporate'
  | 'AutanDefense'
  | 'OffDefense'
  | 'Glade'
  | 'Raid'
  | 'Baygon'
  | 'Duck'
  | 'Pyrel'
  | 'MrMuscle'
  | 'Pledge'
  | 'Drano'
  | 'Lysoform'
  | 'PIDS';

//fonts
const NotoSans = localFont({
  src: [
    {
      path: '../../../public/assets/Fonts/Noto-Sans/NotoSans-Regular.woff2',
      weight: '400',
    },
    {
      path: '../../../public/assets/Fonts/Noto-Sans/NotoSans-Bold.woff2',
      weight: '600',
    },
  ],
  variable: '--Noto-Sans',
});

const NotoSerif = localFont({
  src: [
    {
      path: '../../../public/assets/Fonts/Noto-Serif/NotoSerif-Regular.woff2',
      weight: '400',
    },
  ],
  variable: '--Noto-Serif',
});

const Poppins = localFont({
  src: [
    {
      path: '../../../public/assets/Fonts/poppins/Poppins-SemiBold.woff2',
      weight: '600',
    },
    {
      path: '../../../public/assets/Fonts/poppins/Poppins-Bold.woff2',
      weight: '700',
    },
    {
      path: '../../../public/assets/Fonts/poppins/Poppins-ExtraBold.woff2',
      weight: '800',
    },
  ],
  variable: '--Poppins',
});

// Define the typography map
const typographyMap: TypographyMap = {
  Vanilla: [],
  Ziploc: [],
  Off: [Poppins],
  Autan: [Poppins],
  AutanDefense: [Poppins],
  OffDefense: [Poppins],
  Corporate: [],
  Glade: [],
  Raid: [],
  Baygon: [],
  Duck: [],
  Pyrel: [],
  MrMuscle: [],
  Pledge: [],
  Drano: [],
  Lysoform: [],
  PIDS: [],
};

// Function to generate font classes
export const generateFontClasses = (themeName: ThemeName) => {
  const globalFonts = [NotoSans, NotoSerif];
  const themeFonts = typographyMap[themeName] || [];
  const allFonts = [...globalFonts, ...themeFonts];

  return allFonts.map((font) => font.variable).join(' ');
};
