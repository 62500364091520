// Global
import { tv } from 'tailwind-variants';

// Lib
import { ALL_THEMES } from 'lib/context/ThemeContext';

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

export const tailwindVariants = tv({
  slots: {
    base: [
      'component-content',
      'flex',
      'flex-col',
      'h-full',
      'relative',
      'justify-center',
      'md:min-h-components-hero-internal-spacing-large-minheight',
      'overflow-hidden',
      'md:py-spacing-spacing-7',
      'md:pr-components-hero-internal-spacing-large-padding-right',
      'md:pl-themes-general-surface-spacing-large-padding-x',
      'px-themes-general-surface-spacing-small-padding-x',
      'py-themes-general-surface-spacing-small-padding-y',
    ],
    descriptionText: [
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'text-components-hero-internal-color-default-body',
      'md:font-bodySans-large',
      'md:leading-bodySans-large',
      'md:text-bodySans-large',
      'md:line-clamp-none',
      'pt-[4px]',
      'border-b-4',
      'pb-[4px]',
      'border-transparent',
      'md:max-h-none',
    ],
    svgBackground: [
      'absolute',
      'left-0',
      'top-0',
      'w-full',
      'h-full',
      'overflow-hidden',
      'hidden',
      '[&>*]:h-full',
      '[&>*]:top-0',
      '[&>*]:w-full',
      '[&>*]:absolute',
    ],
    svgDecor: ['w-full', 'absolute', 'bottom-0', 'left-0', 'z-10', 'hidden'],
    textWrapper: [
      'field-heroInternalTitle',
      'flex',
      'flex-col',
      'items-start',
      'relative',
      'z-20',
      'text-components-hero-internal-color-default-title',
    ],
    titleText: [
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
      'mb-themes-general-surface-spacing-small-title-margin-bottom',
      'md:mb-themes-general-surface-spacing-large-title-margin-bottom',
    ],
    contentCtaWrapper: [
      'mt-components-hero-internal-spacing-small-button-padding-y',
      'md:mt-components-hero-internal-spacing-large-button-padding-y',
    ],
    disclaimerClasses: [
      '!text-components-hero-internal-color-default-body',
      'mt-components-hero-internal-spacing-small-button-padding-y',
      'md:mt-components-hero-internal-spacing-large-button-padding-y',
    ],
  },
  variants: {
    alignment: {
      Center: {
        base: ['items-center'],
        descriptionText: ['text-center'],
        titleText: ['text-center'],
        textWrapper: ['items-center'],
      },
      Left: {},
    },
    showPattern: {
      true: {
        base: [
          'bg-components-hero-internal-color-accent-bg',
          'md:bg-components-hero-internal-color-default-bg',
        ],
        svgBackground: ['hidden', 'md:block'],
        svgDecor: ['hidden', 'md:block'],
      },
      false: {
        base: ['bg-components-hero-internal-color-default-bg'],
      },
    },
    brand: {
      ...themeVariants,
      Glade: {
        svgBackground: ['left-[33%]', 'lg:left-[38%]'],
      },
      PIDS: {
        titleText: [
          'font-header-large-small',
          'leading-header-large-small',
          'text-header-large-small',
          'md:font-header-large-medium',
          'md:leading-header-large-medium',
          'md:text-header-large-medium',
        ],
      },
    },
  },
});
export const tailwindVariantsForWithImageVariant = tv({
  slots: {
    base: [
      'flex',
      'flex-col-reverse',
      'md:flex-row',
      'w-full',
      'md:gap-x-spacing-spacing-5',
      'bg-components-hero-internal-color-with-image-bg',
    ],
    leftSideContentWrapper: [
      'flex',
      'flex-col',
      'justify-center',
      'relative',
      'gap-spacing-spacing-5',
      'md:gap-y-components-promo-spacing-large-margin-y',
      'px-themes-general-surface-spacing-small-padding-x',
      'py-themes-general-surface-spacing-small-padding-y',
      'pt-spacing-spacing-10',
      'md:py-spacing-spacing-5',
      'md:pr-spacing-spacing-5',
      'md:pl-themes-general-surface-spacing-large-padding-x',
      'md:w-[34.50%]',
    ],
    logoWrapper: [
      '[&>_img]:rounded-components-hero-internal-spacing-large-icon-radius',
      '[&_img]:h-auto',
      '[&_img]:w-[120px]',
      'max-md:absolute',
      'max-md:-top-[60px]',
    ],
    contentWrapper: ['flex', 'flex-col', 'gap-y-spacing-margin-large-3'],
    contentTitle: [
      'font-header-small-medium',
      'leading-header-small-medium',
      'text-header-small-medium',
      'md:font-header-large-medium',
      'md:leading-header-large-medium',
      'md:text-header-large-medium',
      'text-components-hero-internal-color-with-image-title',
    ],
    contentSubtitle: [
      'font-header-small-xxSmall',
      'leading-header-small-xxSmall',
      'text-header-small-xxSmall',
      'md:font-header-medium-xxSmall',
      'md:leading-header-medium-xxSmall',
      'md:text-header-medium-xxSmall',
      'text-components-hero-internal-color-with-image-body',
    ],
    contentDescription: [
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'text-components-hero-internal-color-with-image-body',
    ],
    contentCtaWrapper: [
      'flex',
      'flex-wrap',
      'gap-themes-general-card-spacing-large-button-space-between',
    ],
    primaryCtaWrapper: ['w-full', 'md:w-auto'],
    secondaryCtaWrapper: ['w-full', 'md:w-auto'],
    disclaimerClasses: ['!text-components-hero-internal-color-with-image-body'],
    rightSideImageWrapper: ['w-full', 'md:w-[65.50%]'],
    imageClasses: ['w-full', 'h-full', 'aspect-video', 'object-fill'],
  },
});
